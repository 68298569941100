import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const Wrapper = styled.div`
	text-align: center;
	padding: 20px 0;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		li {
			overflow: hidden;
			display: block;
			margin: 0 4px;
			button {
				outline: none !important;
				display: block;
				cursor: pointer;
				text-indent: -9999px;
				width: 20px;
				height: 6px;

				transition: background-color 0.3s ease, color 0.3s ease;
				background-color: ${colors.gray};
				border: 1px solid ${colors.grayLight2};
				@media ${device.tablet} {
					width: 40px;
					height: 8px;
				}
			}
			&:hover,
			&.slick-active {
				button {
					background-color: ${colors.black};
				}
			}
		}
	}
`;
