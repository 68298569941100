import React from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";

const Container = styled.div`
	display: flex;
	justify-content: center;
`;

const ComponentLoading = () => {
	return (
		<Container>
			<ReactLoading type="cylon" color="black" width={100} height={100} />
		</Container>
	);
};

export default ComponentLoading;
