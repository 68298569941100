import actionTypes from "../actionTypes";

export const getCarlines = (criteria) => {
	return {
		type: actionTypes.CARLINES_LOAD_INIT,
		payload: criteria,
	};
};

export const getCarline = (criteria) => {
	return {
		type: actionTypes.CARLINE_LOAD_INIT,
		payload: criteria,
	};
};

export const setCarline = (carline) => {
	return {
		type: actionTypes.CARLINE_SET,
		payload: carline,
	};
};

export const setSalesgroup = (salesgroup) => {
	return {
		type: actionTypes.SALESGROUP_SET,
		payload: salesgroup,
	};
};

export const getEscalera = (codigo) => {
	return {
		type: actionTypes.ESCALERA_LOAD_INIT,
		payload: codigo,
	};
};

export const getOfertas = (data) => {
	return {
		type: actionTypes.OFERTAS_LOAD_INIT,
		payload: data,
	};
};
