import styled, { css } from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

const layoutStyles = (props) => {
	if (props.layout === "withBars") {
		return css`
			top: 0;
			width: 1px;
			height: 105px;
			display: flex !important;
			align-items: center;
			@media ${device.tablet} {
				width: 20px;
			}
			${(props) =>
				props.position === "next"
					? css`
							right: 0;
					  `
					: css`
							left: 0;
					  `};

			&:before {
				content: "";
				position: absolute;
				z-index: 1;
				top: 0;
				${(props) =>
					props.position === "next"
						? css`
								left: 0;
						  `
						: css`
								right: 0;
						  `};
				width: 1px;
				height: 100%;
				background-color: ${colors.black};
			}

			span {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				${(props) =>
					props.position === "next"
						? css`
								transform: rotate(-45deg);
								left: 8px;
								@media ${device.tablet} {
									left: 18px;
								}
						  `
						: css`
								transform: rotate(135deg);
								right: 8px;
								@media ${device.tablet} {
									right: 18px;
								}
						  `};
			}
		`;
	} else if (props.layout === "in-out") {
		return css`
			top: 50%;
			transform: translateY(-50%);
			${(props) =>
				props.position === "next"
					? css`
							right: -20px;
							@media ${device.tablet} {
								right: 20px;
							}
							@media ${device.laptopS} {
								right: 26px;
							}
					  `
					: css`
							left: -20px;
							@media ${device.tablet} {
								left: 20px;
							}
							@media ${device.laptopS} {
								left: 26px;
							}
					  `};

			span {
				border-color: ${colors.black};
				@media ${device.tablet} {
					border-color: ${(props) =>
						props.color === "light" ? colors.white : colors.black};
				}

				${(props) =>
					props.position === "next"
						? css`
								transform: rotate(-45deg);
						  `
						: css`
								transform: rotate(135deg);
						  `};
			}
		`;
	} else {
		return css`
			top: 50%;
			transform: translateY(-50%);
			${(props) =>
				props.position === "next"
					? css`
							right: ${props.outside ? "-20px" : "16px"};
							@media ${device.tablet} {
								right: ${props.outside ? "-24px" : "20px"};
							}
							@media ${device.laptopS} {
								right: ${props.outside ? "-30px" : "26px"};
							}
					  `
					: css`
							left: ${props.outside ? "-20px" : "16px"};
							@media ${device.tablet} {
								left: ${props.outside ? "-24px" : "20px"};
							}
							@media ${device.laptopS} {
								left: ${props.outside ? "-30px" : "26px"};
							}
					  `};

			span {
				${(props) =>
					props.position === "next"
						? css`
								transform: rotate(-45deg);
						  `
						: css`
								transform: rotate(135deg);
						  `};
			}
		`;
	}
};

export const Arrow = styled.div`
	position: absolute;
	cursor: pointer;
	z-index: 10;
	transition: opacity 0.3s ease, visibility 0.3s ease;

	span {
		display: block;
		border-style: solid;
		border-color: ${(props) =>
			props.color === "light" ? colors.white : colors.black};
		border-width: 0 1px 1px 0;
		padding: 4px;
		width: 0;
		height: 0;
		@media ${device.tabletL} {
			border-width: 0 2px 2px 0;

			padding: 6px;
		}
	}
	&.slick-disabled {
		visibility: hidden;
		opacity: 0;
	}

	${layoutStyles};
`;
