import React from "react";
import { Wrapper } from "./styled";

const SliderDots = ({ dots }) => {
	return (
		<Wrapper>
			<ul> {dots} </ul>
		</Wrapper>
	);
};

export default SliderDots;
